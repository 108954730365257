@use '@angular/material' as mat;
@use 'sass:map';

/* Theming (Sovity Stock EDC UI) */

$theme-colors-primary: (
  50: #e0e5ec,
  100: #dce4d4,
  200: #c4d4b4,
  300: #c9d1c6,
  400: #9eb097,
  500: #137d44,
  600: #137d44,
  700: #999143,
  800: #f6f6f1,
  900: #1a2f27,
  A100: #548440,
  A200: #9eb097,
  A400: #545454,
  A700: #9eb097,
  contrast: (
    50: #545454,
    100: #545454,
    200: #f6f6f1,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #545454,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$link-color: lighten(map.get($theme-colors-primary, 700), 5%);
$login-color: lighten(map.get($theme-colors-primary, 800), 5%);
$icon-color: lighten(map.get($theme-colors-primary, 900), 5%);
$chip-color:lighten(map.get($theme-colors-primary, 600), 5%);
$nav-list-text-color:lighten(map.get($theme-colors-primary, 900), 5%);


$theme-colors-accent: (
  50: #ffffff,
  100: #545454,
  200: #545454,
  300: #545454,
  400: #545454,
  500: #545454,
  600: #545454,
  700: #545454,
  800: #545454,
  900: #545454,
  A100: #545454,
  A200: #545454,
  A400: #545454,
  A700: #545454,
  contrast: (
    50: #545454,
    100: #545454,
    200: #545454,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #545454,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$theme-palette-primary: mat.define-palette($theme-colors-primary);
$theme-palette-accent: mat.define-palette($theme-colors-accent);
$theme-palette-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  $theme-palette-primary,
  $theme-palette-accent,
  $theme-palette-warn
);
