@forward 'theme';
@forward 'tailwind';
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.broker-online-status-online {
  color: green;
}
.broker-online-status-offline {
  color: #cb8e1a;
}
.broker-online-status-dead {
  color: var(--warn-color);
}
.snackbar-info-style {
  background-color: orange;
  color: black;
}
.snackbar-info-style .mat-simple-snackbar-action  {
  color:var(--chip-color);
}
.snackbar-error-style {
  background-color: crimson;
  color: black;
}
.code {
  font-size: larger;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-hint-text-ellipsis mat-hint {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
.asset-card {
  width: 300px;
}
.list-style-material-icons-check {
  list-style-type: none;
  padding-inline-start: 8px;
}
.list-style-material-icons-check li {
    margin-top: 8px;
  }
.list-style-material-icons-check li:before {
      margin-right: 8px;
      content: 'check';
      font-family: 'Material Icons';
    }
.form-section-title {
  margin: 16px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.04em;
}
.property-grid-group-title {
  margin: 30px 3px 15px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.05em;
}
.mat-form-field-any-size-hint .mat-form-field-subscript-wrapper {
  position: unset !important;
}
// hrefs
.link {
  color: var(--link-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.link:hover,
  .link:focus {
    text-decoration: underline;
  }
.flex-even-sized {
  flex: 1 1 0;
}
.text-slate {
  color: rgba(0, 0, 0, 0.54);
}
.text-warn {
  color: var(--warn-color);
}
.bg-muted {
  background: rgba(0, 0, 0, 0.12);
}
.text-sm {
  font-size: 0.8em;
}
.mat-icon-\[80px\] {
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
  color: var(--icon-color);
}
.mat-icon-\[40px\] {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  color: var(--icon-color);
}
.mat-icon-\[14px\] {
  margin-right: 5px;
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
  color: var(--icon-color);
}
.mat-icon-\[16px\] {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}
.mat-icon-\[10px\] {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  color: var(--icon-color);
}
.mat-icon-\[12px\] {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
  color: var(--icon-color);
}
.mat-icon-\[18px\] {
  margin-top: 5px;
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}
.mat-icon-\[22px\] {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
.mat-icon-\[28px\] {
  margin-top: 8px;
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
  color: var(--icon-color);
}
.mat-icon-\[17px\] {
  font-size: 17px !important;
  line-height: 17px !important;
  width: 17px !important;
  height: 17px !important;
}
.mat-card-header-text {
  overflow-wrap: break-word;
  overflow: hidden;
}
.mat-expansion-panel-flex-col .mat-expansion-panel-body {
  display: flex;
  flex-direction: column;
}
.mat-form-field-without-margin-bottom .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
.max-two-lines-list-item .mat-list-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}
.markdown-description {
  // @apply prose whitespace-normal max-w-none;
  // @apply prose-img:rounded-lg prose-img:my-3 prose-img:object-contain prose-img:max-w-full prose-img:max-h-[250px];
  // @apply prose-a:text-[--link-color];
  // @apply prose-blockquote:mx-0;
  // @apply prose-code:rounded-lg;
  // @apply prose-blockquote:border-solid prose-blockquote:border-t-0 prose-blockquote:border-r-0 prose-blockquote:border-b-0;
  // @apply prose-table:border-collapse prose-table:text-[13px];
  // @apply prose-th:px-[13px] prose-th:py-[6px] prose-th:border prose-th:border-[--tw-prose-td-borders] prose-th:align-middle prose-th:border-solid;
  // @apply prose-td:px-[13px] prose-td:py-[6px] prose-td:border prose-td:border-[--tw-prose-td-borders] prose-td:align-middle prose-td:border-solid;

  font: 400 14px / 20px Open Sans;
  letter-spacing: normal;
}
.markdown-description h1,
  .markdown-description h2,
  .markdown-description h3,
  .markdown-description h4,
  .markdown-description h5 {
    font-weight: 500;
  }
.markdown-description h1 {
    font-size: 19px;
  }
.markdown-description h2 {
    font-size: 17px;
  }
.markdown-description h3 {
    font-size: 15px;
  }
.markdown-description h4 {
    font-size: 14px;
  }
.markdown-description h5 {
    font-size: 12px;
  }
.markdown-description pre code {
    border: initial;
    line-height: 1.5;
  }
.markdown-description a:has(> img){
  width: -moz-fit-content;
  width: fit-content;
}
.markdown-description p:has(> a > img){
  display: flex;
  flex-direction: column;
}
.markdown-description :last-child {
    margin: 0px;
  }
