@use '@angular/material' as mat;
@use 'theme-colors-ubi-sinnogenes' as ubiSinnogenesColors;
@use 'theme-colors-ubi-enflate' as ubiEnflateColors;
@use 'theme-colors' as sovityColors;
@use 'theme-colors.mds' as mdsColors;
@use 'theme-generated-variables' as themeGeneratedVars;

// Import styles that aren't theme dependant (including typography)

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* material-icons-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/material-icons-v140-latin-regular.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans',
);
@include mat.core($custom-typography);

.theme-mds {
  @include mat.all-component-themes(mdsColors.$theme);
  @include themeGeneratedVars.theme-vars(
    mdsColors.$theme,
    mdsColors.$link-color,
    mdsColors.$login-color,
    mdsColors.$icon-color,
    mdsColors.$chip-color,
    mdsColors.$nav-list-text-color
  );
}
// Import ubi enflate theme
.theme-ubi-enflate {
  @include mat.all-component-themes(ubiEnflateColors.$theme);
  @include themeGeneratedVars.theme-vars(
    ubiEnflateColors.$theme,
    ubiEnflateColors.$link-color,
    ubiEnflateColors.$login-color,
    ubiEnflateColors.$icon-color,
    ubiEnflateColors.$chip-color,
    ubiEnflateColors.$nav-list-text-color
  );
  $custom-typography: mat.define-typography-config(
    $font-family: 'Sans-serif',
  );
  @include mat.core($custom-typography);
  //change menu color
  mat-nav-list.mat-nav-list.mat-list-base.overflow-auto.grow {
    background: #1d0840;
    color: white;
  }
  //hover menu color
  mat-list-item :hover {
    background-color: #312249ec;
  }

  //change color to selected menu item
  mat-list-item.mat-list-item.mat-focus-indicator.mat-list-item-with-avatar.is-active {
    // background-color: #ff8b66;
    background-color: #d3cfe5;
  }

  mat-nav-list.mat-list-item.span.mat-line {
    // color: #ff8b66;
    background-color: #d3cfe5;
    color: #3a1772;
  }

  //shadows
  .mat-card:not([class*='mat-elevation-z']) {
    // box-shadow: 0px 0px 7px 0px #2f1f46;
    border: 3px solid #ea9b84;
    border-radius: 20px;
  }

  //change logo background
  mat-toolbar.mat-toolbar.custom-toolbar.shrink-0.ng-tns-c124-0.mat-toolbar-single-row {
    background: linear-gradient(to top, #2f1f46 2%, #76104a 5%, #fafafa 24%);
    margin: unset;
  }

  //change toolbar background
  mat-toolbar.mat-toolbar.mat-primary.mat-toolbar-single-row {
    background: white !important;
    border-bottom: 2px solid #e04b26;
    color: #3b1873;
    font-weight: 600;
  }
  mat-drawer.mat-drawer-side {
    border-color: #2f1f46;
  }
  .mat-option:hover:not(.mat-option-disabled) {
    background: #ff8b6669;
  }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: #e9d6d7;
  }
  .mat-option.mat-active {
    background-color: #e9d6d7;
  }
  .mat-list-option[aria-selected='true'] {
    background: #e9d6d7;
  }
  .mat-list-option:hover:not(.mat-option-disabled) {
    background: #ff8b6669;
  }
}

// Import ubi sinnogenes theme
.theme-ubi-sinnogenes {
  @include mat.all-component-themes(ubiSinnogenesColors.$theme);
  @include themeGeneratedVars.theme-vars(
    ubiSinnogenesColors.$theme,
    ubiSinnogenesColors.$link-color,
    ubiSinnogenesColors.$login-color,
    ubiSinnogenesColors.$icon-color,
    ubiSinnogenesColors.$chip-color,
    ubiSinnogenesColors.$nav-list-text-color
  );
  //change toolbar background
  mat-toolbar.mat-toolbar.mat-primary.mat-toolbar-single-row {
    background: #1a2f27 !important;
  }

  mat-nav-list.mat-nav-list.mat-list-base.overflow-auto.grow {
    background: linear-gradient(to bottom, white 20%, #dce4d4 60%);
    color: #1a2f27;
  }

  .mat-list-item :hover {
    // background-color: #b4c9acad;
    background-color: white;
    border-top: solid 3px #137d44;
    border-bottom: solid 3px #137d44;
    color: #137d44 !important;
    span {
      border: solid 3px transparent;
      color: #137d44 !important;
    }
    mat-icon {
      border: solid 3px transparent;
      color: #137d44 !important;
    }
  }

  mat-list-item.mat-list-item.mat-focus-indicator.mat-list-item-with-avatar.is-active {
    background-color: #b4c9acad;
  }
  mat-nav-list.mat-list-item.span.mat-line {
    color: #1a2f27;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px #545454;
  }

  .mat-option:hover:not(.mat-option-disabled) {
    background-color: white;
    border-top: solid 3px #137d44;
    border-bottom: solid 3px #137d44;
    color: #137d44 !important;
  }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: #b4c9ac73;
  }
  .mat-option.mat-active {
    background-color: #b4c9ac73;
  }
  .mat-list-option[aria-selected='true'] {
    background: #b4c9ac73;
  }
  .mat-list-option:hover:not(.mat-option-disabled) {
    background-color: white;
    border-top: solid 3px #137d44;
    border-bottom: solid 3px #137d44;
    color: #137d44 !important;
  }
  .theme-ubi-sinnogenes
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background-color: #137d44;
    color: white;
  }
}

// fix paginator background, it was white before, but our background is gray
mat-paginator {
  background: transparent !important;
}

// Fix Icon sizes used in card avatars
mat-icon[mat-card-avatar] {
  font-size: 40px !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--icon-color);
}

// Fix mat-chips exploding out and/or having multiline exploding strings
mat-chip {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--chip-color);
}

// Fix Date range second-date-text being "higher" than first-date-text
.mat-date-range-input-container {
  align-items: unset !important;
}
